#bannerStaticPlaceholder {
  height: 50px;
  width: 95vw;
  overflow-y: hidden;
}
#bannerContainer {
  color:white;
  @media screen and (max-width: 600px) {
    padding: 0;
  }
}
#bannerPaper {
  background-image: linear-gradient(90deg, #36b5cc, #095ecc);
  #mobileMenuIconButton {
    position: relative;
    left: 0;
    height: 100%;
    padding: 16px;
    border-radius: 0 !important;
    #mobileMenuIcon {
      border-radius: 0 !important;
      font-size: 2em;
    }
  }
  #bannerLogo {
    height: 40px;
    margin-right: 16px;
  }
  #bannerDesktopButtons {
    display: none;
  }
  @media screen and (max-width: 1200px) {
    #bannerLogo {
      position: absolute;
      right: 0;
      top: 0;
      height: 40px;
      margin: 8px 20px;
    }
  }
  @media screen and (min-width: 1200px) {
    #mobileMenuIconButton {
      display: none;
    }
    #bannerDesktopButtons {
      display: block;
      position: absolute;
      right: 20px;
      .menuItem {
        // color:black;
        margin-right: 16px;
      }
    }
  }
}
