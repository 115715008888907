.listItem {
  // padding: 0;
  margin-bottom: 1px;
  // padding: 8px 0;
  //    padding: 8px 16px 16px;
}
.dropdownParent {
  width: 100%;
  border-radius: 4px !important;
  overflow: hidden;
}

.list {
  width: 100%;
}
.listItem{
  padding:10px 25px !important;
}