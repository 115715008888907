#calcComponent{
  @media screen and (min-width: 1200px) {
    max-width: 1200px;
    margin:25px auto;
    #calcSelectorBar{
      border-radius: 4px;
      max-width: 1152px;
      margin: 0 24px;
    }
  }
}

//CSS for legacy
#calculators {
  text-align: center;
  padding: 10px;
  #calculatorList {
    padding: 0;
    @media screen and (min-width: 1200px) {

      padding: 15px;
    }
    text-align: left;
    position: relative;

    .calculatorItem {
      height: 280px;
      .calculatorItemImage {
        height: 140px;
      }
      .calcItemText {
        height: 140px;
      }
    }
  }
}

.calcItem {
  display: inline-block;
  box-shadow: 0 0 !important;
  padding: 0px;
  &:before {
    height: 280px;

    background: transparent !important;
    border: 0 !important;
  }
  background: transparent !important;
  border: 0 !important;

  &.calcItemActive {
    width: 100%;
    background: transparent !important;
    border: 0 !important;
    position: relative;
    transition: all 0.2s linear;
    .Mui-expanded {
      display: block !important;
    }
  }
  &.calcItemDisabled {
    // width: 33.3%;
    width: 100%;
    transition: all 0.2s linear;
  }
  .calcItemSummary {
    display: block;
    width: 100%;
    background: transparent !important;
    border: 0 !important;
  }
  .calcItemActionArea {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .CalcDetails {
    display: block;
    width: 100%;
    .CalcDetailsCard {
      margin-bottom: 0;
      padding-bottom: 0;
      padding: 10px 15px;
    }
  }
  @media screen and (min-width: 1200px) {
    padding: 10px;
    &.calcItemDisabled {
      width: 33.3%;
    }
    .CalcDetails {
      .CalcDetailsCard {
        padding: 20px 50px;
      }
    }
  }
}

.calculatorName{
  text-align: center;
}

.calculatorDescription{
  // text-align: center;
  font-size: 0.8em;
}