#footer{
    display: none;
    width: 100%;
    text-align:center;
    position: absolute;
    bottom:0;
    color: rgb(190, 190, 190);
    a{
        color: rgb(190, 190, 190);
    }
}