#wiki {
  margin-top: 10px;
  #mainWikiTitle {
    text-align: center;
  }
  .topicSelector {
    margin-top: 20px;

    #topicSelectorDropdown {
      .MuiList-padding {
        padding:0;
      }
      margin: 0;
      padding: 0;
      max-height: 300px;
      overflow-y: auto;
      #topicSelectorList {
        width: 100%;
        .topicSelectorItem {
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .MuiAccordionDetails-root {
          padding: 0;
        }
      }
    }
  }
  #topicSelectorMobile {
    border-radius: 4px !important;
    overflow: hidden;
  }
  #topicSelectorDesktop {
    display: none;
  }
  @media screen and (min-width: 1200px) {
    position: relative;
    #topicSelectorDesktop {
      margin: 15px 0;
      display: block;
    }

    #topicSelectorMobile {
      display: none;
    }
    #wikiContent {
      margin-top: 30px;
      width: 1000px;
    }
  }
}

#wikiContent {
  .desktopTOC {
    display: none;
  }
  .mobileTOC {
    position: fixed;
    z-index: 99;
    right: 20px;
    bottom: 20px;
    .mobileTOCItem {
      background-image: linear-gradient(90deg, #36b5cc, #095ecc);
    }
    .MuiSpeedDialAction-staticTooltipLabel {
      width: unset;
      display: inline !important;
      white-space: nowrap;
      background-image: linear-gradient(90deg, #36b5cc, #095ecc);
    }
  }
  @media screen and (min-width: 1200px) {
    .desktopTOC {
      display: block;
      margin: -50px 24px;
      display: block;
      width: 352px;
      position: fixed;
      left: calc(50vw + 380px);
      .MuiAccordionDetails-root {
        padding: 0;
      }
    }
    .mobileTOC {
      display: none;
    }
  }

  #wikiItemTitle {
    padding: 15px;
    text-align: center;
  }
  #topicTableOfContent {
    margin: 30px 0;
  }
  .topicSection {
    text-align: center;
    padding: 15px;
    position: relative;
    .linkIcon {
      transform: rotate(145deg);
      color: #ACACAC;
      margin-left: 10px;
      margin-right: -58px;
    }
  }
  #shareCard {
    margin: 50px;
    padding: 10px;
  }
  @media screen and (max-width: 1200px) {
    margin-bottom: 60px;
  }
}
