#quickCalc {
  text-align: left;

  // height: 400px;
  background-color: #1e1e1e;
  padding: 15px;
  margin-bottom: 20px; //TEMP
  #quickCalcTitle {
    text-align: left;
  }
  #calcInputs {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-top: 20px;
    gap: 10px;
    row-gap: 15px;

    #qCalcGender {
      width: 100%;
    }
    .qCalcSelectInputs {
      width: 100%;

      .measurementInput {
        width: 55.2%;
        float: left;
        margin: 0;
        padding: 0;
        .measurementInputField {
          border-top-right-radius: 0;
        }
      }
      .unit {
        overflow: hidden;
        float: right;
        .unitSelect {
          border-top-left-radius: 0;
        }
        width: 44%;
        margin: 0;
      }
    }
  }
  //making mat select labels look like textfield labels
  #activityLevel {
    margin-top: 15px;
    width: 100%;
  }
  #quickCalcResult {
    margin: 20px;
    text-align: center;
  }
  #moreCalculatorsButton {
    text-align: right;
    margin-top: 20px;
    // position: absolute;
    // bottom:0;
    // right: 0;
  }
  // position: relative;
}
.measurementInputTest fieldset {
  border-top-right-radius: 0;
}
