.halveInputs {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  // margin-top: 20px;
  gap: 10px;
  row-gap: 15px;
}
.inputWithUnit {
  width: 100%;

  .measurementInput {
    width: 50%;
    margin: 0;
    padding: 0;
    border-top-right-radius: 0;
    // .measurementInputField {
    // }
  }
  .unit fieldset {
    border-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .unit {
    overflow: hidden;
    float: right;
    width: 50%;
    margin: 0;
  }
  @media screen and (min-width: 1200px) {
    .measurementInput {
      width: 60%;
    }
    .unit {
      width: 40%;
    }
  }
}

.fullWidthInput {
  margin-top: 15px !important;
  width: 100%;
  .inputWithUnit {
    .measurementInput {
      width: 60%;
    }
    .unit {
      width: 40%;
    }
  }
  @media screen and (min-width: 1200px) {
    .inputWithUnit {
      .measurementInput {
        width: 80%;
      }
      .unit {
        width: 20%;
      }
    }
  }
}
.inputWithUnit {
  .unit {
    //0.7 opacity original
    border-left: 1px solid rgba(255, 255, 255, 0.5);
  }
}
#result {
  margin: 20px;
  text-align: center;
}


//Remove number input arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.detailsAccordion{
  width: 100%;
  @media screen and (min-width: 1200px) {
    width: 80%;
  }
}
//Specifics
.bmiSlider {
  // display: inline-block;
  width: 80%;
  margin: 40px 10% 0 10%;
  // margin-bottom:0
  .bmiSliderText {
    // padding:40px;
    margin-top: 60px;
    // padding:0;
    // margin:0;
  }
  @media screen and (max-width: 1200px) {
    .bmiSliderText {
      margin-top: 20px;
    }
    width: 100%;
    margin: 50px 0;
    .MuiSlider-markLabel {
      display: none;
    }
  }
}
.tdeeTable {
  width: 100%;
  padding:0 15px;
  
  .tableElement {
    margin: auto;
  }
  @media screen and (min-width: 1200px) {
    padding:0;
    .tableElement {
      width: 70%;
    }
  }
  .cell {
    text-align: center;
  }
}
.wikiButton {
  width: 100%;
  @media screen and (min-width: 1200px) {
    left: 15%;
    width: 70%;
  }
}
