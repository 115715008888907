.listItem {
  padding: 0;
  margin-bottom: 1px;
}
.dropdownParent {
  width: 100%;
  border-radius: 4px !important;
  overflow: hidden;
}

.list {
  width: 100%;
}
.listItem{
  padding:5px;
}