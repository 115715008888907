@import '../../colors.scss';
#home {
  text-align: center;
  @media screen and (max-width: 1200px) {
    max-width: 600px;
    margin: 20px auto;
  }
}
#homeIntro {
  position: relative;
  z-index: -1;
  overflow: hidden;
  #homeIntroFade {
    overflow: hidden;
    z-index: 1;
    border-radius: 5px;
    background-image: linear-gradient(#ffffff00 70%, #ffffff 100%);
  }
  #homeIntroImage {
    position: relative;
    top:10px;
    margin-top:-30px;
    border-radius: 5px;
    z-index: -1;
    overflow: hidden;
    left: 0;
    min-height: 200px;
    #homeIntroImageLo {
      width: 100%;
    }
  }
  @media screen and (min-width: 1200px) {
    top: 40px;
    margin-top: -20px;
  }
}
#homeButtons {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 25px;
  margin: -20px 4px 20px;
  justify-content: center;

  text-align: center;

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    // gap: 15px;
    justify-content: center;
    // margin: auto;
    // margin: auto;
    // width: 260px;
  }
  .homeButton {
    .homeButtonActionArea {
      padding: 0px 15px;
      // padding:0;
      margin: 0;
      height: 100%;
      width: 100%;
    }
    .homeButtonTitle {
      margin-top: -30px;
    }
    // justify-content: center;
    // text-align: center;
    // display: inline-block;
    //320
    height: 200px;
    max-width: 290px;
    // @media screen and (min-width: 650px) {
    //   padding: 10px 15px;
    // margin: auto;
    //   // width: 290px;
    // }
    .homeButtonIcon {
      margin: 20px;
      font-size: 5em;
    }
    .homeButtonImageIcon {
      width: 80px;
      margin: 10px;
    }
  }
}
#homeQuickCalc {
  @media screen and (min-width: 1200px) {
    width: 69% !important;
    float: left;
    height: 430px;
  }
}
#discordWidget {
  width: 100%;
  @media screen and (min-width: 1200px) {
    width: 30%;
    height: 430px;
    float: right;
  }
}
