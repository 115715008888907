.buttonRoot {
  width: 100%;
  text-align: left;
}

.detailsSpanStyle {
  margin-left: 15px;
}

.expandIconStyle {
  position: absolute;
  right: 20px;
  transform: rotate(0deg);

  &.expanded {
    animation: spin 0.1s linear;
    transform: rotate(180deg);
  }
  &.collapsed {
    animation: spin2 0.1s linear;
    transform: rotate(0deg);
  }
}
.dividerStyle {
  width: 100%;
  text-align: left;
  margin: 0;
  margin-top: 10px;
}
.hiddenAccordion {
  margin: auto !important;
  // width: 80% !important;
  box-shadow: 0 0 !important;
  &:before {
    height: 280px;

    background: transparent !important;
    border: 0 !important;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes spin2 {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
